.app {
  text-align: center;
  min-height: 100vh;
}

.body {
  text-align: center;
  min-height: 100vh;
}
  
@media (prefers-reduced-motion: no-preference) {
  
}
