.section {
    font-size: 30px;
    color: white;
    padding-top: 150px;
    text-align: center;
}

.section-title {
    z-index: 999;
    color: #71ce7e;
    box-shadow: 0 0px 5px 2px #71ce7e;
    margin: auto;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
    margin-top: 30px;
    border-radius: 5px;
}

.section-backdrop {
    width: 60%;
    margin: auto;
    background-color: #272727;
    padding: 40px;
    font-size: 1.5rem;
    max-width: 1000px; 
    margin-top: 50px;
}

.left {
    box-shadow: -60px -50px #71ce7e;
}

.right {
    box-shadow: 60px -50px #71ce7e;
}