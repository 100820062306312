.home-section {
    min-height: 100vh;
}

.home-content {
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 2;
    text-align: center;
    width: 100%;
}

.bg-image {
    /* The image used */
    background-image: url(../../../assets/background.jpg);
  
    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);

    /* Full height */
    height: 100vh;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    margin-top: 20vh;
    margin-bottom: 10vh;
    animation: fadeInAnimation ease 1s;
    width: 60%;
    min-width: 5vh;
    max-width: 50vh;
    border: 2px solid #71ce7e;
    border-radius: 5px;
}

.scroll-box {
    width: 300px;
    margin: auto;
    background-color: #00000066;
    color: #71ce7e;
    font-weight: bold;
    font-size: 25px;
    text-decoration: none;
    border: 2px solid #71ce7e;
    border-radius: 5px;
    padding: 8px;
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

html {
    scroll-behavior: smooth;
}