.navigation {
    display: flex;
    float: right;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 1px;
}

.navigation-item {
    list-style: none;
}

.navbar {
    position: sticky;
    top: 0;
    overflow: hidden;
    background-color: #272727;
    z-index: 999;
    padding-right: 80px;
    box-shadow: 0 4px 10px -2px #272727;
}

.navbar a {
    text-align: center;
    padding: 14px;
    text-decoration: none;
}  

.inactive-section {
    color: #ffffff
}

.active-section {
    color: #71ce7e;
}

@media (max-width: 768px) {
    .navbar {
        display: none;
    }
}